$(function () {

    /**
     * How it works landing recruiter & talent
     */
    $('#works-carousel').slick({
        accessibility: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 7000,
        dots: true,
        appendDots: $('.works-nav'),
        customPaging: function(slider, i) {
            var currentSlide = i + 1;
            return '<div class="works__content--small">' +
                '            <a href="#step1" class="works__content__number works__content__number--small">' +
                '                <div>' +
                '                    <span class="text-outline">' + currentSlide + '</span>' +
                '                </div>' +
                '            </a>' +
                '            <a href="#step1" class="works__content__title">' + $(slider.$slides[i]).attr('title') + '</a>' +
                '        </div>';
        }
    });


    /**
     * Ressources sur mobile
     */
    $('#resource-carousel').slick({
        accessibility: false,
        dots: true,
        infinite: false,
        arrows: false,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
        customPaging: function(slider, i) {
            var currentSlide = i + 1;
            return '<button type="button" class="gradient-square">' + currentSlide + '</button>';
        }
    });


    /**
     * Presse
     */
    $('#press-carousel').slick({
        accessibility: false,
        dots: false,
        infinite: true,
        arrows: false,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: true,
        centerPadding: 0,
        vertical: true
    });


    /**
     * Partenaires sur mobile
     */
    $('#partner-carousel').slick({
        accessibility: false,
        dots: true,
        infinite: false,
        arrows: false,
        speed: 300,
        slidesToShow: 1,
        centerPadding: 0,
        centerMode: true,
        customPaging: function(slider, i) {
            var currentSlide = i + 1;
            return '<button type="button" class="gradient-square">' + currentSlide + '</button>';
        }
    });


    /**
     * Investisseurs sur mobile
     */
    $('#invest-carousel').slick({
        accessibility: false,
        dots: true,
        infinite: false,
        arrows: false,
        speed: 300,
        slidesToShow: 1,
        centerPadding: 0,
        centerMode: true,
        customPaging: function(slider, i) {
            var currentSlide = i + 1;
            return '<button type="button" class="gradient-square">' + currentSlide + '</button>';
        }
    });
    
   
    /**
     * testimony front-page
     */
    if (typeof $imageTalent !== 'undefined' && $imageRecruiter !== 'undefined' && $companyLogo !== 'undefined' ) {

        var imageTalentJson = $imageTalent,
            imageRecruiterJson = $imageRecruiter,
            companyLogoJson = $companyLogo,
            k = -1;

        $('#testimony-carousel').slick({
            accessibility: false,
            dots: true,
            infinite: false,
            arrows: false,
            speed: 600,
            slidesToShow: 1,
            initialSlide: 0,
            fade: true,
            cssEase: 'linear',
            appendDots: $('.testimony-nav'),
            customPaging: function(slider, i) {
                var currentSlide = i + 1;

                while( k < 3 ) {
                    k++;
                    return '<div class="testimony-img">\n' +
                    '\n' +
                    '                    <img src="'+ imageTalentJson[k] +'" class="img-rounded testimony-img__up">\n' +
                    '                    <img src="'+ imageRecruiterJson[k] +'" class="img-rounded testimony-img__down">\n' +
                    '\n' +
                    '                    <div class="has-text-centered">\n' +
                    '                        <img src="'+ companyLogoJson[k] +'" alt="Codeworks" class="testimony-img__logo">\n' +
                    '                    </div>\n' +  
                    '                </div>';
                
                }
            
            }
        });
    }


    /**
     * testimony recruiter, talent, single-salary & page-builder
     */
    $('#testimony-single-carousel').slick({
        accessibility: false,
        dots: false,
        infinite: true,
        arrows: true,
        speed: 300,
        slidesToShow: 1,
        appendArrows: $('.testimony-arrow__nav'),
        nextArrow: '<button type="button" class="testimony-arrow__nav__next"><img src="'+$wpUrl+'/assets/img/icons/arrow-right-gradient.svg" alt="Suivant"></button>',
        prevArrow: '<button type="button" class="testimony-arrow__nav__prev"><img src="'+$wpUrl+'/assets/img/icons/arrow-left-gradient.svg" alt="Précédent"></button>'
    });

});